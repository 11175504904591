import React, { useEffect, useState, useRef } from "react";
import { Col, Row, Card, Form, Spinner, Button } from "react-bootstrap";
import Loader from "components/Loader/Loader";
import useToggle from "hooks/useToggle";
import { get, put } from "utils/BeeApi";
import NotificationToast from "components/NotificationToast/NotificationToast";
import "./Account.scss";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import {
  Onboarding,
  treatmentGroupId,
  diagnosisGroupId,
} from "components/PrivatePages/Home/Onboarding/Onboarding";

const Account = ({ user }) => {
  const [subscriber, setSubscriber] = useState(null);
  const [loading, toggleLoading] = useToggle(false);
  const [isSubmitting, toggleIsSubmitting] = useToggle(false);
  const [notify, toggleNotify] = useToggle(false);
  const [error, setError] = useState(null);
  const [showOnboarding, toggleShowOnboarding] = useToggle(false);

  const subscribedPromised = useRef([]);

  useEffect(() => {
    fetchSubscriber();
    const promises = subscribedPromised.current;
    return () => {
      promises.forEach((promise) => promise.cancel());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOnboarding]);

  const fetchSubscriber = () => {
    toggleLoading();
    const createPromise = get("/login", {});
    createPromise.promise.then((response) => {
      setSubscriber(response.data);
      toggleLoading();
    });

    subscribedPromised.current.push(createPromise);
  };

  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    about: "",
  });

  useEffect(() => {
    setForm({
      firstName: subscriber?.firstName || "",
      lastName: subscriber?.lastName || "",
      about: subscriber?.about || "",
    });
  }, [subscriber]);

  const setFormData = (event) => {
    const { name, value } = event.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubscriberUpdate = (event) => {
    event.preventDefault();
    toggleIsSubmitting();
    const createPromise = put(`/user/${user?.sub}`, form);
    createPromise.promise
      .then((response) => {
        setSubscriber(response.data);
        toggleNotify();
      })
      .catch((error) => setError(error))
      .finally(() => {
        toggleIsSubmitting();
      });

    subscribedPromised.current.push(createPromise);
  };

  const getDiagnosis = () => {
    const { categories = [] } = subscriber ?? {};
    return (
      categories.filter(
        (category) => category.groups[0].groupId === diagnosisGroupId
      )[0]?.categoryName || "N/A"
    );
  };

  const getTreatment = () => {
    const { categories = [] } = subscriber ?? {};
    return (
      categories.filter(
        (category) => category.groups[0].groupId === treatmentGroupId
      )[0]?.categoryName || "N/A"
    );
  };

  const renderSubscriber = () => {
    return (
      <Card className="h-100 shadow-sm border-0">
        <Card.Body>
          <div className="account-settings">
            <div className="UserProfile">
              <div className="pt-2 pb-3 ProfilePictureDefault">
                <div className="pic">{`${subscriber?.firstName.charAt(
                  0
                )}${subscriber?.lastName.charAt(0)}`}</div>
              </div>
              <div className="text-center">
                <h3>{`${subscriber?.firstName} ${subscriber?.lastName}`}</h3>
                <p>
                  <small>{subscriber?.email}</small>
                </p>
                <h4>Treatment </h4>
                <h5 className="text-muted">{getTreatment()}</h5>
                <h4>Diagnosis</h4>
                <h5 className="text-muted">{getDiagnosis()}</h5>

                <Button variant="primary" block onClick={toggleShowOnboarding}>
                  Edit Journey
                </Button>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  };

  const renderUserProfileForm = () => {
    return (
      <Card className="h-100 shadow-sm border-0">
        <Card.Body>
          <Form onSubmit={handleSubscriberUpdate}>
            <Row className="gutters">
              <Col xs={12}>
                <h4 className="mb-2 text-primary">Personal Details</h4>
              </Col>
              <Col xs={12} lg={6} md={6} sm={6}>
                <Form.Label className="mt-2">First Name</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  id="firstName"
                  value={form.firstName}
                  placeholder="Enter First Name"
                  onChange={setFormData}
                />
              </Col>
              <Col xs={12} lg={6} md={6} sm={6}>
                <Form.Label className="mt-2">Last Name</Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  id="lastName"
                  value={form.lastName}
                  placeholder="Enter Last Name"
                  onChange={setFormData}
                />
              </Col>
              <Col xs={12}>
                <Form.Label className="mt-2">Email</Form.Label>
                <Form.Control
                  type="text"
                  readOnly
                  name="email"
                  value={subscriber?.email || ""}
                  id="email"
                  placeholder="Your email"
                />
              </Col>
              <Col xs={12}>
                <Form.Label className="mt-2">About</Form.Label>
                <Form.Control
                  as="textarea"
                  name="about"
                  id="about"
                  value={form.about}
                  placeholder="Tell us something about yourself"
                  onChange={setFormData}
                />
              </Col>
              <Col className="mt-3">
                {error && <ErrorHandler error={error} />}
                <Button
                  className="float-right pr-5 pl-5"
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                >
                  {isSubmitting && (
                    <Spinner
                      animation="border"
                      variant="light"
                      size="sm"
                      className="mr-1"
                    />
                  )}
                  Update
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    );
  };

  if (loading) return <Loader />;

  return (
    <>
      <Row>
        <Col xl={3} lg={3} md={4} sm={12} xs={12} className="mt-4">
          {renderSubscriber()}
        </Col>
        <Col xl={9} lg={9} md={8} sm={12} xs={12} className="mt-4">
          {renderUserProfileForm()}
        </Col>
      </Row>
      <NotificationToast isVisible={notify} onClose={toggleNotify} />
      <Onboarding isVisible={showOnboarding} onClose={toggleShowOnboarding} />
    </>
  );
};

export default Account;
