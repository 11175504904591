import React, { useEffect, useState } from "react";

const GreetUser = ({ user }) => {
  const [timeOfTheDay, setTimeOfTheDay] = useState("Morning");

  useEffect(() => {
    const now = new Date();
    const hrs = now.getHours();

    if (hrs > 0) setTimeOfTheDay("night");
    if (hrs > 6) setTimeOfTheDay("morning");
    if (hrs > 12) setTimeOfTheDay("afternoon");
    if (hrs > 16) setTimeOfTheDay("evening");
    if (hrs > 19) setTimeOfTheDay("night");
  }, [setTimeOfTheDay]);

  return (
    <h1>
      Good {timeOfTheDay}, {user?.firstName}
    </h1>
  );
};

export default GreetUser;
