import React from "react";
import { Toast } from "react-bootstrap";
import "./NotificationToast.scss";

const NotificationToast = ({
  isVisible = false,
  variant = "success",
  onClose,
  message = "Action performed Successfully.",
}) => {
  return (
    <div className="toast-container" position="bottom-end">
      <Toast
        onClose={onClose}
        show={isVisible}
        className={`bg-${variant}`}
        autohide
      >
        <Toast.Body className="text-light">{message}</Toast.Body>
      </Toast>
    </div>
  );
};

export default NotificationToast;
