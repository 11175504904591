import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  Container,
  Col,
  Row,
  Button,
  Form,
  InputGroup,
  Spinner,
  Card,
} from "react-bootstrap";

import "./Login.scss";
import { isLoggedIn, login, get } from "../../../utils/BeeApi";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import EyeOutlineIcon from "mdi-react/EyeOutlineIcon";
import EyeOffOutlineIcon from "mdi-react/EyeOffOutlineIcon";

import {
  isLoggedIn as isLoggedInDE,
  login as loginDE,
} from "../../../utils/DeApi";
import TextLogo from "../TextLogo/TextLogo";

/**
 * Handles logging in to the application.
 * Handles single sign on.
 */
class Login extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this._handleSubmit.bind(this);
    this.handleEmailChange = this._handleEmailChange.bind(this);
    this.handlePasswordChange = this._handlePasswordChange.bind(this);
    this.toggleVisiblePassword = this._toggleVisiblePassword.bind(this);

    const loggedIn = isLoggedIn() && isLoggedInDE();
    let email = loggedIn ? loggedIn.email : "";
    let pattern = new RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

    this.state = {
      email: email,
      password: "",
      emailValid: pattern.test(email),
      passwordValid: false,
      error: "",
      loggedIn: loggedIn,
      formSubmitted: false,
    };
  }

  componentDidMount() {}

  _handleEmailChange(event) {
    let email = event.target.value;
    let pattern = new RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

    this.setState({
      email: email,
      emailValid: pattern.test(email),
    });
  }

  _handlePasswordChange(event) {
    let password = event.target.value;
    this.setState({
      password: password,
      passwordValid: password.length ? true : false,
    });
  }

  _toggleVisiblePassword() {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }

  login() {
    this.setState({
      error: "",
      isLoading: true,
    });
    login({
      email: this.state.email,
      password: this.state.password,
    })
      .then((data) => {
        const createPromise = get("/login", {});
        console.log(createPromise);
        createPromise.promise.then((response) => {
          localStorage.setItem("utype", response.data.type);
        });
        this.setState({
          loggedIn: true,
          isLoading: false,
        });
        // return loginDE();
      })
      // .then((data) => {
      //   this.setState({
      //     loggedIn: true,
      //     isLoading: false,
      //   });
      // })
      .catch((error) => {
        console.log(error);
        this.setState({
          error: error,
          isLoading: false,
        });
      });
  }

  _handleSubmit(event) {
    event.preventDefault();
    this.setState(
      {
        formSubmitted: true,
      },
      (state, props) => {
        if (this.state.emailValid && this.state.passwordValid) this.login();
      }
    );
  }

  renderLoginForm() {
    const {
      email,
      emailValid,
      password,
      passwordValid,
      error,
      formSubmitted,
      showPassword,
      isLoading,
    } = this.state;
    return (
      <Form onSubmit={this.handleSubmit}>
        <div className="text-center">
          <h2 className="pre-title">Login to continue</h2>
        </div>
        <hr />
        <Form.Group className="mb-3">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            value={email}
            onChange={this.handleEmailChange}
            isInvalid={email !== "" && !emailValid && !formSubmitted}
            placeholder="Enter email"
            autoFocus
          />
          <Form.Control.Feedback type="invalid">
            This should be a valid email address.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Password</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={this.handlePasswordChange}
              isInvalid={password.length < 5 && !passwordValid && formSubmitted}
              placeholder="Password"
            />
            <InputGroup.Prepend>
              <Button variant="light" onClick={this.toggleVisiblePassword}>
                {showPassword ? (
                  <EyeOutlineIcon className="text-muted" size={18} />
                ) : (
                  <EyeOffOutlineIcon className="text-muted" size={18} />
                )}
              </Button>
            </InputGroup.Prepend>
            <Form.Control.Feedback type="invalid">
              Enter a valid password.
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        {error && <ErrorHandler error={error} />}
        <div className="d-grid gap-2 mb-3">
          <Button type="submit" color="primary" block disabled={isLoading}>
            {isLoading && (
              <Spinner
                className="mr-2"
                animation="border"
                size="sm"
                variant="light"
              />
            )}
            Sign in
          </Button>
        </div>
        <p className="text-muted text-center mt-sm mb-sm">
          <small>
            By continuing, you agree to our{" "}
            <a
              target="_blank"
              href="https://inkindspace.com/our-privacy-policy/"
              rel="noreferrer"
            >
              Terms of Service
            </a>{" "}
            and have read and acknowledge our{" "}
            <a
              target="_blank"
              href="https://inkindspace.com/our-privacy-policy"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            .
          </small>
        </p>
        <hr />
        <p className="text-center">
          <Link to="/login-help">Forgot Password?</Link>
        </p>
        <p className="text-center">
          Not a member yet? <Link to="/sign-up">Create an account.</Link>
        </p>
      </Form>
    );
  }

  render() {
    const { loggedIn } = this.state;

    if (loggedIn) {
      let { from } = this.props.location.state || {
        from: { pathname: "/" },
      };
      return <Redirect to={from} />;
    }

    return (
      <Container className="Login mt-5 mb-5">
        <Row>
          <Col
            xl={{ span: 4, offset: 4 }}
            lg={{ span: 4, offset: 4 }}
            md={{ span: 6, offset: 3 }}
            sm={{ span: 8, offset: 2 }}
          >
            <TextLogo />
            <Card className="border-0 shadow-sm">
              <Card.Body>{this.renderLoginForm()}</Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Login;
