import React, { Fragment } from "react";
import "./ContentExternal.css";
import { getURLDomain } from "../../../utils/StringUtils";
import moment from "moment";
import FavoriteIcon from "../FavoriteIcon/FavoriteIcon";

const ContentExternal = ({
  title,
  contentBody,
  rankDate,
  contentExternalLink,
  contentId,
  updatedAt,
}) => {
  return (
    <Fragment>
      <h1>
        <big>{title}</big>
      </h1>
      <FavoriteIcon
        classes="mr-2"
        contentId={contentId}
        shouldFetchBookmark={true}
      />
      <small className="text-muted">{moment(updatedAt).format("LL")}</small>
      <div
        className="body-section mt-4 mb-4"
        dangerouslySetInnerHTML={{ __html: contentBody }}
      />
      <div className="mb-4">
        <a
          rel="noopener noreferrer"
          target="_blank"
          className="btn btn-primary"
          href={contentExternalLink}
        >
          View on {getURLDomain(contentExternalLink)}
        </a>
      </div>
    </Fragment>
  );
};

export default ContentExternal;
