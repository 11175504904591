import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  Container,
  Col,
  Row,
  Button,
  Alert,
  Form,
  InputGroup,
  Spinner,
  Card,
} from "react-bootstrap";

import "./ResetPassword.css";
import { isLoggedIn, put } from "../../../utils/BeeApi";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import { isLoggedIn as isLoggedInDE } from "../../../utils/DeApi";
import EyeOutlineIcon from "mdi-react/EyeOutlineIcon";
import EyeOffOutlineIcon from "mdi-react/EyeOffOutlineIcon";
import TextLogo from "../TextLogo/TextLogo";

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this._handleSubmit.bind(this);
    this.handlePasswordChange = this._handlePasswordChange.bind(this);
    this.handleConfirmPasswordChange =
      this._handleConfirmPasswordChange.bind(this);
    this.handleEmailChange = this._handleEmailChange.bind(this);
    this.toggleVisiblePassword = this._toggleVisiblePassword.bind(this);

    const loggedIn = isLoggedInDE() && isLoggedIn();
    let email = loggedIn ? loggedIn.email : "";
    let validationToken = this.parseURLParams(props.location.search).get(
      "verification"
    );

    let pattern = new RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

    this.state = {
      email: email,
      emailValid: pattern.test(email),
      password: "",
      confirmPassword: "",
      passwordValid: false,
      confirmPasswordValid: false,
      error: "",
      loggedIn: loggedIn,
      validationToken: validationToken || "",
      formSubmitted: false,
    };

    this.subscribedPromises = [];
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function (promise) {
      promise.cancel();
    });
  }

  parseURLParams(params) {
    return new URLSearchParams(params || "");
  }

  _handleEmailChange(event) {
    let email = event.target.value;
    let pattern = new RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    this.setState({
      email: email,
      emailValid: pattern.test(email),
    });
  }

  _handlePasswordChange(event) {
    let password = event.target.value;
    let pattern = new RegExp(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/);
    this.setState({
      password: password,
      passwordValid: pattern.test(password),
    });
  }

  _handleConfirmPasswordChange(event) {
    const { password } = this.state;
    let confirmPassword = event.target.value;
    let pattern = new RegExp(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/);
    this.setState({
      confirmPassword: confirmPassword,
      confirmPasswordValid:
        pattern.test(confirmPassword) && confirmPassword === password,
    });
  }

  _toggleVisiblePassword() {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }

  _handleSubmit(event) {
    event.preventDefault();

    const { confirmPassword, password } = this.state;

    this.setState(
      {
        formSubmitted: true,
        confirmPasswordValid: confirmPassword === password,
      },
      (state, props) => {
        const { emailValid, passwordValid, confirmPasswordValid } = this.state;

        if (emailValid && passwordValid && confirmPasswordValid) {
          this.setState({
            error: "",
            isLoading: true,
          });

          const createPromise = put("/user/anon/password", {
            userId: "anon",
            email: this.state.email,
            token: this.state.validationToken,
            password: this.state.password,
            password_confirmation: this.state.confirmPassword,
          });
          createPromise.promise
            .then((response) => {
              this.setState({
                isLoading: false,
                error: "",
              });

              //On success redirect to login.
              this.props.history.push({
                pathname: "/login",
                state: this.state,
              });
            })
            .catch((error) => {
              this.setState({
                error: error,
                isLoading: false,
              });
            });

          this.subscribedPromises.push(createPromise);
        }
      }
    );
  }

  renderResetPasswordForm() {
    const {
      email,
      emailValid,
      password,
      passwordValid,
      confirmPassword,
      confirmPasswordValid,
      error,
      formSubmitted,
      showPassword,
      isLoading,
    } = this.state;

    return (
      <Form onSubmit={this.handleSubmit}>
        <div className="mt-sm mb-md text-center">
          <h2 className="pre-title">Set Password</h2>
          <p>Enter your email and a new password for your account.</p>
        </div>
        <hr />
        <Form.Group>
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            value={email}
            onChange={this.handleEmailChange}
            isInvalid={!emailValid && formSubmitted}
            placeholder="Enter email"
            autoFocus
          />
          <Form.Control.Feedback type="invalid">
            This should be a valid email address
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Password</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={this.handlePasswordChange}
              isInvalid={!passwordValid && formSubmitted}
              placeholder="Password"
            />
            <InputGroup.Prepend>
              <Button variant="light" onClick={this.toggleVisiblePassword}>
                {showPassword ? (
                  <EyeOutlineIcon className="text-muted" size={18} />
                ) : (
                  <EyeOffOutlineIcon className="text-muted" size={18} />
                )}
              </Button>
            </InputGroup.Prepend>
            <Form.Control.Feedback type="invalid">
              Password must contain at least 6 characters, including
              UPPER/lowercase and numbers.
            </Form.Control.Feedback>
          </InputGroup>
          <Form.Control.Feedback type="invalid">
            Password must contain at least 6 characters, including
            UPPER/lowercase and numbers.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-4 text-input">
          <Form.Label>Confirm Password</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              type={showPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={this.handleConfirmPasswordChange}
              isInvalid={!confirmPasswordValid && formSubmitted}
              placeholder="Confirm Password"
            />
            <InputGroup.Prepend>
              <Button variant="light" onClick={this.toggleVisiblePassword}>
                {showPassword ? (
                  <EyeOutlineIcon className="text-muted" size={18} />
                ) : (
                  <EyeOffOutlineIcon className="text-muted" size={18} />
                )}
              </Button>
            </InputGroup.Prepend>
            <Form.Control.Feedback type="invalid">
              Confirm password should match the password.
            </Form.Control.Feedback>
          </InputGroup>
          <Form.Control.Feedback type="invalid">
            Confirm password must match with password
          </Form.Control.Feedback>
        </Form.Group>

        {error &&
          ((error) => {
            if (error.status === 403) {
              return (
                <Alert variant="warning" className="mt-md mb-md">
                  <p>
                    Sorry, your link to set a new password has expired or is
                    invalid. Please request for another link{" "}
                    <Link to="/login-help">here</Link>.
                  </p>
                </Alert>
              );
            } else {
              return <ErrorHandler error={error} />;
            }
          })(error)}

        <Button
          className="mb-4"
          type="submit"
          color="primary"
          block
          disabled={isLoading}
        >
          {isLoading && (
            <Spinner
              className="mr-2"
              animation="border"
              size="sm"
              variant="light"
            />
          )}
          Continue
        </Button>
        <p className="text-center">
          <Link to="/login">Cancel</Link>
        </p>
      </Form>
    );
  }

  render() {
    const { loggedIn } = this.state;
    if (loggedIn) {
      let { from } = this.props.location.state || {
        from: { pathname: "/" },
      };
      return <Redirect to={from} />;
    }

    return (
      <Container className="ResetPassword mt-5 mb-5">
        <Row>
          <Col
            xl={{ span: 4, offset: 4 }}
            lg={{ span: 4, offset: 4 }}
            md={{ span: 6, offset: 3 }}
            sm={{ span: 8, offset: 2 }}
          >
            <TextLogo />
            <Card className="border-0 shadow-sm">
              <Card.Body>{this.renderResetPasswordForm()}</Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ResetPassword;
