import React, { useState, useEffect, useRef } from "react";
import { Tab, Tabs, OverlayTrigger, Tooltip } from "react-bootstrap";
import HeartIcon from "mdi-react/HeartIcon";
import ContentSummaryList from "../../../Content/ContentSummaryList/ContentSummaryList";
import "./Recommended.scss";
import { get } from "utils/BeeApi";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Favorites from "components/PrivatePages/Home/Recommended/Favorites/Favorites";
import { Link } from "react-router-dom";

const groups = {
  treatmentPhaseId: "583d956e85acf",
  diagnosisId: "583d957b71f0f",
};

const categories = {
  beautyPersonalCareId: "583d966e4a4b6",
  diagnosisTreatmentId: "5aced388c9052",
  wellnessId: "5aced3b641148",
  fitnessId: "583d96439e65c",
  nutritionId: "583d964d8a8ff",
  mediaId: "5a626cc5ca37e",
  tipsId: "583db2faed75d",
};

const Recommended = ({ user }) => {
  const [key, setKey] = useState(categories.beautyPersonalCareId);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [journeyCategories, setJourneyCategories] = useState([]);
  const subscribedPromises = useRef([]);

  const fetchUserCategories = (id) => {
    setIsLoading(true);
    const createPromise = get(`/user/${id}`, {
      params: {
        includes: ["groups"],
      },
    });
    createPromise.promise
      .then((response) => {
        const { categories } = response.data;
        setJourneyCategories(categories);
        setIsLoading(false);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      });

    subscribedPromises.current.push(createPromise);
  };

  useEffect(() => {
    fetchUserCategories(user?.sub);
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [user?.sub]);

  const queryBuilder = (recommendedCategoryId = "", operand = " OR ") => {
    const _categories = journeyCategories.filter((category) => {
      return (
        category.groups.filter(
          ({ groupId }) => groupId === groups.treatmentPhaseId
        ).length > 0
      );
    });

    const categoryIds = _categories.map(({ categoryId }) => categoryId);
    const query = `categories.category_id:( ${recommendedCategoryId} AND ${categories.mediaId} OR ${categories.tipsId} ) AND status:true`;

    if (categoryIds.length > 0)
      return `${query} AND categories.category_id:(${categoryIds.join(
        operand
      )})`;

    return query;
  };

  const renderJourneyCategories = () => {
    const _categories = journeyCategories.filter((category) => {
      return (
        category.groups.filter(
          ({ groupId }) =>
            groupId === groups.treatmentPhaseId ||
            groupId === groups.diagnosisId
        ).length > 0
      );
    });
    return (
      <p className="mt-2 mb-0 text-right">
        {_categories.map((category, index) => (
          <small key={category.categoryId} className="mr-2 text-muted">
            {index !== 0 ? <span className="mr-2">|</span> : ""}
            {category.categoryName}
          </small>
        ))}
        <small style={{ textDecoration: "underline" }}>
          <Link to="/account">Profile</Link>
        </small>
      </p>
    );
  };

  return (
    <div className="recommended">
      {/* <OverlayTrigger
        trigger={["hover", "focus"]}
        key={"recommended"}
        placement={"right"}
        overlay={
          <Tooltip id={`tooltip-recommended`}>
            Recommended based on your profile and journey phase.
          </Tooltip>
        }
      >
        {({ ref, ...triggerHandler }) => (
          <h2 {...triggerHandler}>
            Articles For You <span ref={ref} />
          </h2>
        )}
      </OverlayTrigger> */}

      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        transition={false}
        onSelect={(key) => setKey(key)}
        mountOnEnter
        unmountOnExit
        // className="recommended-tabs flex-nowrap"
      >
        <Tab
          className="pl-0"
          eventKey={categories.beautyPersonalCareId}
          title={"Personal Care"}
        >
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {renderJourneyCategories()}
              <ContentSummaryList
                limit={1000}
                elasticSearchQuery={queryBuilder(
                  categories.beautyPersonalCareId
                )}
              />
            </>
          )}
        </Tab>
        <Tab eventKey={categories.diagnosisTreatmentId} title={"Treatment"}>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {renderJourneyCategories()}
              <ContentSummaryList
                limit={1000}
                elasticSearchQuery={queryBuilder(
                  categories.diagnosisTreatmentId
                )}
              />
            </>
          )}
        </Tab>
        <Tab eventKey={categories.wellnessId} title={"Wellness"}>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {renderJourneyCategories()}
              <ContentSummaryList
                limit={1000}
                elasticSearchQuery={queryBuilder(categories.wellnessId)}
              />
            </>
          )}
        </Tab>
        <Tab eventKey={categories.fitnessId} title={"Fitness"}>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {renderJourneyCategories()}
              <ContentSummaryList
                limit={1000}
                elasticSearchQuery={queryBuilder(categories.fitnessId)}
              />
            </>
          )}
        </Tab>
        <Tab eventKey={categories.nutritionId} title={"Nutrition"}>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {renderJourneyCategories()}
              <ContentSummaryList
                limit={1000}
                elasticSearchQuery={queryBuilder(categories.nutritionId)}
              />
            </>
          )}
        </Tab>
        <Tab eventKey={"Favorites"} title={<HeartIcon color="#b3b3b3" />}>
          {renderJourneyCategories()}
          <Favorites user={user} />
        </Tab>
      </Tabs>
      {error && <ErrorHandler error={error} />}
    </div>
  );
};

export default Recommended;
