import { Modal, Form, Button, Spinner, Col } from "react-bootstrap";
import useToggle from "hooks/useToggle";
import React, { useEffect, useState, useRef } from "react";
import { get } from "utils/BeeApi";
import "./Onboarding.scss";
import { put } from "utils/BeeApi";
import { isLoggedIn } from "utils/BeeApi";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";

export const treatmentGroupId = "583d956e85acf";
export const diagnosisGroupId = "583d957b71f0f";

export const Onboarding = ({ isVisible = false, onClose = () => null }) => {
  const [isLoading, toggleLoading] = useToggle(false);
  const [categories, setCategories] = useState({
    [treatmentGroupId]: "",
    [diagnosisGroupId]: "",
  });
  const [error, setError] = useState(null);

  const [treatmentCategories, setTreatmentCategories] = useState([]);
  const [diagnosisCategories, setDiagnosisCategories] = useState([]);
  const subscribedPromises = useRef([]);

  const fetchTreatementCategories = () => {
    const { promise, cancel } = get("categories", {
      params: { group: treatmentGroupId },
    });
    promise
      .then((response) => {
        setTreatmentCategories(response?.data?.reverse() || []);
      })
      .catch((error) => {
        const { isCanceled } = error;
        if (!isCanceled) {
          setError(error);
        }
      });

    subscribedPromises.current.push(cancel);
  };

  useEffect(() => {
    fetchTreatementCategories();
  }, []);

  const fetchDiagnosisCategories = () => {
    const { promise, cancel } = get("categories", {
      params: { group: diagnosisGroupId },
    });
    promise
      .then((response) => {
        setDiagnosisCategories(response?.data || []);
      })
      .catch((error) => setError(error));

    subscribedPromises.current.push(cancel);
  };

  useEffect(() => {
    fetchDiagnosisCategories();
  }, []);

  const fetchUserCategories = () => {
    const user = isLoggedIn();
    const { promise, cancel } = get(`/user/${user?.sub}`, {
      params: {
        includes: ["groups"],
      },
    });
    promise
      .then((response) => {
        let { categories } = response?.data;
        categories = categories.map((category) => {
          return { [category.groups[0].groupId]: category.categoryId };
        });
        setCategories(Object.assign({}, ...categories));
      })
      .catch((error) => setError(error));

    subscribedPromises.current.push(cancel);
  };

  useEffect(() => {
    fetchUserCategories();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const user = isLoggedIn();
    toggleLoading();
    const { promise, cancel } = put(`/user/${user?.sub}/categories`, {
      categories: Object.values(categories).filter(
        (category) => category !== "choose"
      ),
    });
    promise
      .then((response) => {
        onClose();
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => {
        toggleLoading();
      });

    subscribedPromises.current.push(cancel);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCategories({
      ...categories,
      [name]: value,
    });
  };

  useEffect(() => {
    const cancels = subscribedPromises.current;
    return () => {
      cancels.forEach((cancel) => cancel());
    };
  }, []);

  return (
    <Modal show={isVisible} onHide={onClose}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title className="font-weight-bold">Your journey</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Tell us where you are so we can personalize your experience. As you
            move along your journey, you can update it in your profile.
          </p>
          <Form.Row>
            <Form.Group as={Col} xs={12} controlId="formGridState">
              <Form.Label className="font-weight-bold">
                Treatment Phase
              </Form.Label>
              <Form.Control
                as="select"
                defaultValue="choose"
                onChange={handleChange}
                name={treatmentGroupId}
                value={categories[treatmentGroupId]}
              >
                <option value="choose">Choose...</option>
                {treatmentCategories.map((treatmentCategory) => (
                  <option
                    key={treatmentCategory.categoryId}
                    value={treatmentCategory.categoryId}
                  >
                    {treatmentCategory.categoryName}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} xs={12} controlId="formGridState">
              <Form.Label className="font-weight-bold">Diagnosis</Form.Label>
              <Form.Control
                as="select"
                defaultValue="choose"
                onChange={handleChange}
                name={diagnosisGroupId}
                value={categories[diagnosisGroupId]}
              >
                <option value="choose">Choose...</option>
                {diagnosisCategories.map((diagnosisCategory) => (
                  <option
                    key={diagnosisCategory.categoryId}
                    value={diagnosisCategory.categoryId}
                  >
                    {diagnosisCategory.categoryName}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            {error && <ErrorHandler error={error} />}
          </Form.Row>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button variant="link" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" variant="primary" disabled={isLoading}>
            {isLoading && (
              <Spinner
                className="mr-2"
                animation="border"
                size="sm"
                variant="light"
              />
            )}
            Update
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
