import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./CheckIn.scss";
import { get } from "utils/BeeApi";
import ContentSummaryList from "components/Content/ContentSummaryList/ContentSummaryList";
import GreetUser from "components/App/GreetUser/GreetUser";

const groups = {
  treatmentPhaseId: "583d956e85acf",
};

const moods = [
  {
    mood: "happy",
    emoji: "😁",
    categoryId: "61241cdc7533b",
  },
  {
    mood: "sad",
    emoji: "😞",
    categoryId: "61241cea5a16d",
  },
  {
    mood: "angry",
    emoji: "😠",
    categoryId: "61241d112df15",
  },
  {
    mood: "overwhelmed",
    emoji: "😣",
    categoryId: "61241d01ac719",
  },
  {
    mood: "tired",
    emoji: "😴",
    categoryId: "61241d255b137",
  },
  {
    mood: "anxious",
    emoji: "😰",
    categoryId: "61241d326894f",
  },
];

const CheckIn = ({ user }) => {
  const [mood, setMood] = useState(localStorage.getItem("moodId"));
  const [journeyCategories, setJourneyCategories] = useState([]);
  const [, setIsLoading] = useState(false);
  const subscribedPromises = useRef([]);

  const fetchUserCategories = (id) => {
    setIsLoading(true);
    const createPromise = get(`/user/${id}`, {
      params: {
        includes: ["groups"],
      },
    });
    createPromise.promise
      .then((response) => {
        const { categories } = response.data;
        setJourneyCategories(categories);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });

    subscribedPromises.current.push(createPromise);
  };

  const handleMoodSelection = (id) => {
    const _id = mood !== id ? id : "";
    setMood(_id);
    localStorage.setItem("moodId", _id);
  };

  useEffect(() => {
    fetchUserCategories(user?.sub);
  }, [user?.sub]);

  const queryBuilder = (moodCategoryId = "", operand = " OR ") => {
    const _categories = journeyCategories.filter((category) => {
      return (
        category.groups.filter(
          ({ groupId }) => groupId === groups.treatmentPhaseId
        ).length > 0
      );
    });

    const categoryIds = _categories.map(({ categoryId }) => categoryId);
    const query = `categories.category_id:( ${moodCategoryId} ) AND status:true`;

    if (categoryIds.length > 0)
      return `${query} AND categories.category_id:(${categoryIds.join(
        operand
      )})`;

    return query;
  };

  return (
    <Row className="check-in">
      <Col xs={12} className="mt-4">
        <Card className="shadow-sm border-0" style={{ minHeight: 210 }}>
          <Row>
            <Col lg={6} md={12} sm={12} xs={12}>
              <Card.Body>
                <GreetUser user={user} />
                <hr />
                <p>
                  Check in daily for recommendations based on how you’re feeling
                  right now.
                </p>
                <div className="mt-2">
                  How are you feeling today?
                  <p className="mt-2" style={{ fontSize: 30 }}>
                    {moods.map((item) => (
                      <OverlayTrigger
                        key={item.categoryId}
                        placement={"top"}
                        overlay={
                          <Tooltip id={`tooltip-${item.categoryId}`}>
                            {item.mood}
                          </Tooltip>
                        }
                      >
                        <span
                          className={`mr-2 mood ${
                            mood === item.categoryId
                              ? "selected rounded-sm"
                              : ""
                          }`}
                          onClick={() => handleMoodSelection(item.categoryId)}
                        >
                          {item.emoji}
                        </span>
                      </OverlayTrigger>
                    ))}
                  </p>
                </div>
              </Card.Body>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col xs={12} className="mt-4">
        {mood && (
          <>
            {/* <h2 className="pt-0 mb-0">Based On Your Mood</h2> */}
            <ContentSummaryList
              limit={1000}
              elasticSearchQuery={queryBuilder(mood)}
              // showWithSlider
            />
          </>
        )}
      </Col>
    </Row>
  );
};

export default CheckIn;
