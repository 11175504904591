import React, { useEffect, useState, useRef } from "react";
import "./Favorites.scss";
import { get } from "utils/BeeApi";
import { Alert, Col, Row, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { slugify } from "utils/StringUtils";
import Loader from "components/Loader/Loader";
import FavoriteIcon from "components/Content/FavoriteIcon/FavoriteIcon";
import HeartOutlineIcon from "mdi-react/HeartOutlineIcon";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";

const Favorites = ({ user }) => {
  const [isLoading, setLoading] = useState(false);
  const [bookmarks, setBookmarks] = useState([]);
  const [error, setError] = useState("");

  const subscribedPromises = useRef([]);

  const removeBookmark = (contentId) => {
    const data = bookmarks.filter((bookmark) => {
      return bookmark?.bookmarkable?.contentId !== contentId;
    });
    setBookmarks(data || []);
  };

  const fetchBookmarks = (id) => {
    setLoading(true);
    const createPromise = get(`/user/${id}/bookmarks`);

    createPromise.promise
      .then((response) => {
        setLoading(false);
        setBookmarks(response?.data || []);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setLoading(false);
      });

    subscribedPromises.current.push(createPromise);
  };

  useEffect(() => {
    fetchBookmarks(user?.sub);
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => promise.cancel());
    };
  }, [user?.sub]);

  if (isLoading) return <Loader />;

  if (error) return <ErrorHandler error={error} />;

  if (bookmarks.length === 0)
    return (
      <Alert variant="info" className="mt-4">
        <h4>No Favorites!</h4>
        <p>
          You do not have any favorites. Click the {<HeartOutlineIcon />} Icon
          on articles to save them as favorites.
        </p>
      </Alert>
    );

  return (
    <div className="favorites">
      <h2 className="mt-4 mb-0 pb-0">My Favorites</h2>
      <Row>
        {bookmarks.map(({ bookmarkable }) => (
          <Col
            lg={3}
            md={6}
            sm={12}
            xs={12}
            key={bookmarkable.contentId}
            className="mt-4"
          >
            <Card className="shadow-sm bg-body border-0">
              <Card.Img variant="top" src={bookmarkable.contentThumbnail} />
              <Card.Body className="px-3">
                <h4 className="centered-card-title">
                  <Link
                    className="hyperlink-1 content-title"
                    to={`/contents/${bookmarkable.contentId}/${slugify(
                      bookmarkable?.title
                    )}`}
                  >
                    {bookmarkable?.title}
                  </Link>
                </h4>
                <p className="mb-2">
                  <FavoriteIcon
                    contentId={bookmarkable?.contentId}
                    classes="float-left"
                    markedFavorite={true}
                    removeBookmarkCallback={removeBookmark}
                  />
                  <Link
                    className="read-more float-right"
                    to={`/contents/${bookmarkable.contentId}/${slugify(
                      bookmarkable.title
                    )}`}
                  >
                    Read More
                  </Link>
                </p>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Favorites;
