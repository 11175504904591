import React, { Component } from "react";
import "./ContentSummaryList.scss";
import { Link } from "react-router-dom";
import { get, isLoggedIn } from "../../../utils/BeeApi";
import { slugify } from "../../../utils/StringUtils";
import {
  Button,
  Alert,
  Col,
  Row,
  Card,
  Carousel,
  CarouselItem,
} from "react-bootstrap";
import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import FavoriteIcon from "../FavoriteIcon/FavoriteIcon";

class ContentSummaryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      bookend: true,
      contentSummaryList: [],
      bookmarks: [],
      error: "",
      showWithSlider: props.showWithSlider || false,
    };
    this.subscribedPromises = [];
    this.handleBookend = this._handleBookend.bind(this);
  }

  componentDidMount() {
    this.fetchContents();
    this.fetchBookmarks();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.elasticSearchQuery !== this.props.elasticSearchQuery) {
      this.fetchContents();
    }
  }

  fetchContents = () => {
    const { limit, elasticSearchQuery } = this.props;

    this.setState({
      error: "",
      isLoading: true,
    });

    const options = {
      sort: "date",
      type: "bool",
      q: elasticSearchQuery,
      limit: limit || 10,
      offset: this.props.offset || 0,
    };

    const createPromise = get("/search/contents", { params: options });

    createPromise.promise
      .then((response) => {
        const contentSummaryList = response.data;

        this.setState({
          contentSummaryList: contentSummaryList,
          error: "",
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          error: !error.isCanceled ? error : "",
        });
      });

    this.subscribedPromises.push(createPromise);
  };

  componentWillUnmount() {
    this.subscribedPromises.forEach((promise) => {
      promise.cancel();
    });
  }

  render() {
    const { error, isLoading, contentSummaryList, showWithSlider } = this.state;
    const { ContentSummaryList, ShowMore } = this;

    if (error) return <ErrorHandler error={error} />;
    if (isLoading) return <Loader />;

    if (contentSummaryList.length <= 0)
      return (
        <Alert className="mt-1" variant="info">
          <Alert.Heading as="h3">No content found</Alert.Heading>
          <p>There are no intels associated with your selected filters</p>
        </Alert>
      );

    return (
      <div className="ContentSummaryList">
        <ContentSummaryList showWithSlider={showWithSlider} />
        <ShowMore />
      </div>
    );
  }

  expandContentSummaryList(offset) {
    this.setState({
      expandingError: "",
      isExpanding: true,
      offset: offset,
    });

    const { limit, operand, rank, category } = this.props;
    const options = {
      limit: limit || 10,
      offset: offset || 0,
      operand: operand || "or",
      rank: rank || null,
      category: category || null,
    };

    const contentSummaryListPromise = get("/search/contents", {
      params: options,
    });
    contentSummaryListPromise.promise
      .then((response) => {
        this.setState((prevState) => {
          return {
            contentSummaryList: prevState.contentSummaryList
              ? prevState.contentSummaryList.concat(...response.data)
              : response.data,
            error: "",
            isExpanding: false,
          };
        });
      })
      .catch((error) => {
        !error.isCanceled &&
          this.setState({
            isExpanding: false,
            expandingError: error,
          });
      });

    this.subscribedPromises.push(contentSummaryListPromise);
  }

  _handleBookend() {
    const { contentSummaryList } = this.state;
    let offset = this.state.offset
      ? this.state.offset + this.props.limit
      : contentSummaryList.length;

    this.expandContentSummaryList(offset);
  }

  ShowMore = () => {
    const { isExpanding, expandingError, contentSummaryList } = this.state;
    let limit = this.props.limit || 10;

    if (expandingError)
      return (
        <div className="mb-5 mt-5">
          <ErrorHandler error={expandingError} />
        </div>
      );

    if (isExpanding)
      return (
        <div className="mb-5">
          <Loader />
        </div>
      );

    if (contentSummaryList.length <= 0 || !this.props.bookend) return <div />;

    return (
      contentSummaryList.length % limit === 0 && (
        <div className="text-center mt-5">
          <Button block variant="light" onClick={this.handleBookend}>
            Show more
          </Button>
        </div>
      )
    );
  };

  fetchBookmarks() {
    const user = isLoggedIn();
    const getBookmarks = get(`/user/${user?.sub}/bookmarks`);
    getBookmarks.promise.then((response) => {
      const contentIds = [];
      response.data.map(({ bookmarkable }) => {
        contentIds.push(bookmarkable.contentId);
        return bookmarkable;
      });
      this.setState({
        bookmarks: contentIds,
      });
    });

    this.subscribedPromises.push(getBookmarks);
  }

  isContentBookmarked(contentId) {
    const { bookmarks } = this.state;
    return bookmarks.includes(contentId);
  }

  createChunksForSlider(items) {
    return items.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / 2);
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }
      resultArray[chunkIndex].push(item);
      return resultArray;
    }, []);
  }

  ContentSummaryCard = ({ content, isInSlider = false }) => {
    return (
      <Col
        lg={isInSlider ? 4 : 3}
        md={6}
        sm={12}
        xs={12}
        key={content.contentId}
        className="mt-4 float-left align-self-center"
      >
        <Card className="shadow-sm bg-body border-0">
          <Card.Img variant="top" src={content.contentThumbnail} />
          <Card.Body className="px-3">
            <h4 className="centered-card-title">
              <Link
                className="hyperlink-1 content-title"
                to={`/contents/${content.contentId}/${slugify(content.title)}`}
              >
                {content.title}
              </Link>
            </h4>
            <p className="text-center mb-2">
              <FavoriteIcon
                contentId={content?.contentId}
                classes="float-left"
                markedFavorite={this.isContentBookmarked(content?.contentId)}
              />

              <Link
                className="read-more float-right"
                to={`/contents/${content.contentId}/${slugify(content.title)}`}
              >
                Read More
              </Link>
            </p>
          </Card.Body>
        </Card>
      </Col>
    );
  };

  ContentSummaryList = ({ showWithSlider }) => {
    const { ContentSummaryCard, ContentSummarySlider } = this;
    const { contentSummaryList } = this.state;
    if (showWithSlider) return <ContentSummarySlider />;
    return (
      <Row key={Math.random()}>
        {contentSummaryList &&
          contentSummaryList.map((content) => (
            <ContentSummaryCard content={content} />
          ))}
      </Row>
    );
  };

  ContentSummarySlider = () => {
    const { contentSummaryList } = this.state;
    const { ContentSummaryCard } = this;
    const chunkedContent = this.createChunksForSlider(contentSummaryList);
    return (
      <Carousel interval={null}>
        {chunkedContent.map((chunkedContent, mainIndex) => {
          return (
            <CarouselItem key={mainIndex}>
              <Row
                key={mainIndex + Math.random()}
                className="justify-content-md-center"
              >
                {chunkedContent.map((content, index) => (
                  <ContentSummaryCard
                    key={index + mainIndex}
                    content={content}
                    isInSlider
                  />
                ))}
              </Row>
            </CarouselItem>
          );
        })}
      </Carousel>
    );
  };
}

export default ContentSummaryList;
