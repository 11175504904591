import axios from "axios";
import {
  setToken,
  getToken,
  readToken,
  clearToken,
  validateToken,
} from "./AuthService";
import { MakeCancelable } from "./CancelablePromise";

/**
 * Handles API access to BEE.
 * Builds http requests via axios.
 * Adds Authorization headers and http errors interceptors.
 * Converts http requests to cancelable promises to allow cancellation of promises for unmounted components.
 */

const TOKEN_NAME = "bee-token";
const END_POINT = "https://breastcue.pixeledge.io/api/v2";

var instance = axios.create({
  baseURL: END_POINT,
  headers: { common: { Authorization: `Bearer ${getToken(TOKEN_NAME)}` } },
});

registerInterceptor(instance);

// Add a response interceptor for error handling
function registerInterceptor(instance) {
  instance.interceptors.response.use(
    function (response) {
      // Success
      return Promise.resolve(response.data);
    },
    function (error) {
      // Do something with response error
      if (error.response) {
        // The request was made and the server responded with a status code
        return Promise.reject({
          data: error.response.data,
          status: error.response.status,
          statusText: error.response.statusText,
        });
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        return Promise.reject({
          data: "",
          status: 0,
          statusText: "No Response",
        });
      } else {
        // Something happened in setting up the request that triggered an Error
        return Promise.reject({
          data: "",
          status: 0,
          statusText: error.message,
        });
      }
    }
  );
}

function isLoggedIn() {
  if (validateToken(TOKEN_NAME)) return readToken(TOKEN_NAME);
  else return false;
}

function login(data) {
  return instance.post("/login", data).then((response) => {
    setToken(TOKEN_NAME, response.data && response.data.token);
    instance = axios.create({
      baseURL: END_POINT,
      headers: {
        common: { Authorization: `Bearer ${getToken(TOKEN_NAME)}` },
      },
    });
    registerInterceptor(instance);

    return Promise.resolve({ data: readToken(TOKEN_NAME) });
  });
}

function logout() {
  clearToken(TOKEN_NAME);
  return Promise.resolve({ data: { message: "success" } });
}

function get(url, data) {
  return MakeCancelable(instance.get(url, data));
}

function post(url, data) {
  return MakeCancelable(instance.post(url, data));
}

function put(url, data) {
  return MakeCancelable(instance.put(url, data));
}

function destroy(url) {
  return MakeCancelable(instance.delete(url));
}

export { isLoggedIn, login, logout, post, get, put, destroy };
