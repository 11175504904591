import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  Alert,
  Container,
  Col,
  Row,
  Button,
  Form,
  InputGroup,
  Spinner,
  Card,
} from "react-bootstrap";

import "./Signup.css";
import { isLoggedIn, post } from "../../../utils/BeeApi";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import { isLoggedIn as isLoggedInDE } from "../../../utils/DeApi";
import EyeOutlineIcon from "mdi-react/EyeOutlineIcon";
import EyeOffOutlineIcon from "mdi-react/EyeOffOutlineIcon";
import TextLogo from "../TextLogo/TextLogo";

class Signup extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this._handleSubmit.bind(this);
    this.handleFirstNameChange = this._handleFirstNameChange.bind(this);
    this.handleLastNameChange = this._handleLastNameChange.bind(this);
    this.handleEmailChange = this._handleEmailChange.bind(this);
    this.handlePasswordChange = this._handlePasswordChange.bind(this);
    this.toggleVisiblePassword = this._toggleVisiblePassword.bind(this);

    const loggedIn = isLoggedInDE() && isLoggedIn();
    let email = loggedIn ? loggedIn.email : "";

    this.state = {
      email: email,
      password: "",
      emailValid: !!email,
      passwordValid: false,
      confirmPassword: "",
      confirmPasswordValid: false,
      firstNameValid: false,
      firstName: "",
      lastNameValid: false,
      lastName: "",
      formError: "",
      loggedIn: loggedIn,
      success: false,
    };

    this.subscribedPromises = [];
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function (promise) {
      promise.cancel();
    });
  }

  _handleFirstNameChange(event) {
    let firstName = event.target.value;
    this.setState({
      firstName: firstName,
      firstNameValid: firstName.length > 2 ? true : false,
    });
  }

  _handleLastNameChange(event) {
    let lastName = event.target.value;
    this.setState({
      lastName: lastName,
      lastNameValid: lastName.length > 2 ? true : false,
    });
  }

  _handleEmailChange(event) {
    let email = event.target.value;
    let pattern = new RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

    this.setState({
      email: email,
      emailValid: pattern.test(email),
    });
  }

  _handlePasswordChange(event) {
    let password = event.target.value;
    let pattern = new RegExp(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/);

    this.setState({
      password: password,
      passwordValid: pattern.test(password),
      confirmPassword: password,
      confirmPasswordValid: pattern.test(password),
    });
  }

  _toggleVisiblePassword() {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }

  _handleSubmit(event) {
    event.preventDefault();
    const { confirmPassword, password } = this.state;
    this.setState(
      {
        formSubmitted: true,
        confirmPasswordValid: confirmPassword === password,
      },
      (state, props) => {
        const { emailValid, passwordValid, confirmPasswordValid } = this.state;
        if (emailValid && passwordValid && confirmPasswordValid) {
          this.setState({
            error: "",
            isLoading: true,
          });

          const createPromise = post("/user", {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            password: this.state.password,
            password_confirmation: this.state.confirmPassword,
          });

          createPromise.promise
            .then((response) => {
              this.setState({
                isLoading: false,
                error: "",
                success: true,
              });
            })
            .catch((error) => {
              this.setState({
                error: error,
                isLoading: false,
              });
            });

          this.subscribedPromises.push(createPromise);
        }
      }
    );
  }

  renderRegistrationForm() {
    const {
      error,
      success,
      firstName,
      firstNameValid,
      lastName,
      lastNameValid,
      email,
      emailValid,
      password,
      passwordValid,
      showPassword,
      formSubmitted,
      isLoading,
    } = this.state;
    return (
      <Form onSubmit={this.handleSubmit}>
        <div className="text-center">
          <h2 className="pre-title">Create an account</h2>
        </div>
        <hr />
        <Form.Row>
          <Form.Group as={Col} xs="12">
            <Form.Label>First name</Form.Label>
            <Form.Control
              type="text"
              value={firstName}
              onChange={this.handleFirstNameChange}
              isInvalid={!firstNameValid && formSubmitted}
              placeholder="Enter first name"
              autoFocus
            />
            <Form.Control.Feedback type="invalid">
              First name is required
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} xs="12">
            <Form.Label>Last name</Form.Label>
            <Form.Control
              type="text"
              value={lastName}
              onChange={this.handleLastNameChange}
              isInvalid={!lastNameValid && formSubmitted}
              placeholder="Enter last name"
            />
            <Form.Control.Feedback type="invalid">
              Last name is required
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="12">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              value={email}
              onChange={this.handleEmailChange}
              isInvalid={!emailValid && formSubmitted}
              placeholder="Enter email"
            />
            <Form.Control.Feedback type="invalid">
              This should be a valid email address
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" as={Col} xs="12">
            <Form.Label>Password</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={this.handlePasswordChange}
                isInvalid={!passwordValid && formSubmitted}
                placeholder="Password"
              />
              <InputGroup.Prepend>
                <Button variant="light" onClick={this.toggleVisiblePassword}>
                  {showPassword ? (
                    <EyeOutlineIcon className="text-muted" size={18} />
                  ) : (
                    <EyeOffOutlineIcon className="text-muted" size={18} />
                  )}
                </Button>
              </InputGroup.Prepend>
              <Form.Control.Feedback type="invalid">
                Password must contain at least 6 characters, including
                UPPER/lowercase and numbers.
              </Form.Control.Feedback>
            </InputGroup>
            <Form.Control.Feedback type="invalid">
              Password must contain at least 6 characters, including
              UPPER/lowercase and numbers.
            </Form.Control.Feedback>
          </Form.Group>
          {error &&
            ((error) => {
              if (error.status === 409) {
                return (
                  <Alert variant="warning" className="mt-md mb-md">
                    <h3>Conflict</h3>
                    <p>
                      An account associated with this email address already
                      exists. Please use a different email address.
                    </p>
                  </Alert>
                );
              } else {
                return <ErrorHandler error={error} />;
              }
            })(error)}
          {success && (
            <Alert variant="success">
              <h4>Success!</h4>
              <p>
                Your account has been created,{" "}
                <Link to="/login" className="font-weight-bold alert-success">
                  click here to login.
                </Link>
              </p>
            </Alert>
          )}
          <Col md={12}>
            <Button type="submit" color="primary" block disabled={isLoading}>
              {isLoading && (
                <Spinner
                  className="mr-2"
                  animation="border"
                  size="sm"
                  variant="light"
                />
              )}
              Create Account
            </Button>
            <p className="text-center pt-3">
              <Link to="/login">Already have an account?</Link>
            </p>
          </Col>
        </Form.Row>
      </Form>
    );
  }

  render() {
    const { loggedIn } = this.state;
    if (loggedIn) {
      let { from } = this.props.location.state || {
        from: { pathname: "/" },
      };
      return <Redirect to={from} />;
    }

    return (
      <Container className="Login mt-5 mb-5">
        <Row>
          <Col
            xl={{ span: 4, offset: 4 }}
            lg={{ span: 4, offset: 4 }}
            md={{ span: 6, offset: 3 }}
            sm={{ span: 8, offset: 2 }}
          >
            <TextLogo />
            <Card className="border-0 shadow-sm">
              <Card.Body>{this.renderRegistrationForm()}</Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Signup;
