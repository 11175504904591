import React from "react";
import { Container } from "react-bootstrap";
import { Route, Switch } from "react-router-dom";

import AccountHeader from "../App/AccountHeader/AccountHeader";
import NoMatch from "../App/NoMatch/NoMatch";
import Content from "../Content/Content";
import Search from "./Search/Search";
import Home from "./Home/Home";
import Account from "components/PrivatePages/Account/Account";
import Favorites from "components/PrivatePages/Favorites/Favorites";
import Footer from "components/App/Footer/Footer";
import CheckIn from "./CheckIn/CheckIn";

const PrivatePages = (props) => {
  const { user } = props;
  return (
    <>
      <AccountHeader user={props.user} title="" />
      <Container className="mb-5 mt-3 MainContent">
        <Switch>
          <Route
            exact
            path="/account"
            render={(props) => <Account {...props} />}
          />
          <Route
            exact
            path="/favorites"
            render={(props) => <Favorites {...props} />}
          />
          <Route
            exact
            path="/search"
            render={(props) => <Search user={user} />}
          />
          <Route
            exact
            path="/check-in"
            render={(props) => <CheckIn user={user} />}
          />
          <Route
            exact
            path="/contents/:contentId/:slug"
            render={(props) => (
              <Content contentId={props.match.params.contentId} />
            )}
          />
          <Route
            exact
            path="/"
            render={(props) => (
              <Home
                user={user}
                history={props.history}
                location={props.location}
              />
            )}
          />
          <Route component={NoMatch} />
        </Switch>
      </Container>
      <Footer />
    </>
  );
};

export default PrivatePages;
