import React from "react";
import { Link } from "react-router-dom";
import "./TextLogo.scss";

const TextLogo = () => (
  <div className="text-center mt-5 mb-4 Logo">
    <Link to="/">
      <h1>
        <span>InKind</span> Space
      </h1>
    </Link>
  </div>
);

export default TextLogo;
