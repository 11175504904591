import React, { Fragment } from "react";
import "./ContentArticle.css";
import moment from "moment";
import FavoriteIcon from "../FavoriteIcon/FavoriteIcon";

const ContentArticle = ({
  title,
  description,
  contentBody,
  rankDate,
  contentId,
  showFavoriteIcon = true,
  updatedAt,
}) => {
  return (
    <Fragment>
      <h1>
        <big>{title}</big>
      </h1>
      <FavoriteIcon
        show={showFavoriteIcon}
        classes="mr-2"
        contentId={contentId}
        shouldFetchBookmark={true}
      />
      <small className="text-muted">{moment(updatedAt).format("LL")}</small>
      <div
        className="body-section mt-4 mb-4"
        dangerouslySetInnerHTML={{ __html: contentBody }}
      />
    </Fragment>
  );
};

export default ContentArticle;
