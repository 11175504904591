import useToggle from "hooks/useToggle";
import React, { useCallback, useEffect, useRef } from "react";
import { Spinner } from "react-bootstrap";
import { get, destroy, isLoggedIn } from "utils/BeeApi";
import HeartOutlineIcon from "mdi-react/HeartOutlineIcon";
import HeartIcon from "mdi-react/HeartIcon";
import "./FavoriteIcon.scss";
import { put } from "utils/BeeApi";

const FavoriteIcon = ({
  contentId,
  classes = "",
  markedFavorite = false,
  bookmarkCallback = () => null,
  removeBookmarkCallback = () => null,
  shouldFetchBookmark = false,
  show = true,
}) => {
  const [isFavoritted, toggleIsFavoritted] = useToggle(markedFavorite);
  const [isFavoriting, toggleIsFavoriting] = useToggle(false);
  const [isFetching, toggleIsFetching] = useToggle(false);

  const subscribedPromises = useRef([]);

  const user = isLoggedIn();

  // this method needs to go inside useCallback because its been called by
  // a hook useEffect, react will throw a warning to if we remove useCallback from here.
  const fetchBookmark = useCallback(() => {
    toggleIsFetching();
    const { promise, cancel } = get(
      `/user/${user?.sub}/bookmarks/${contentId}`
    );
    subscribedPromises.current.push(cancel);
    promise
      .then((response) => {
        toggleIsFavoritted();
      })
      .catch((error) => {
        //
      })
      .finally(() => {
        toggleIsFetching();
      });
  }, [contentId, toggleIsFavoritted, toggleIsFetching, user?.sub]);

  const addBookmark = () => {
    toggleIsFavoriting();
    const { promise, cancel } = put(`/user/${user?.sub}/bookmarks`, {
      bookmarkableId: contentId,
    });
    subscribedPromises.current.push(cancel);
    promise
      .then((response) => {
        toggleIsFavoritted();
        bookmarkCallback(contentId);
      })
      .finally(() => {
        toggleIsFavoriting();
      });
  };

  const removeBookmark = () => {
    toggleIsFavoriting();
    const { promise, cancel } = destroy(
      `/user/${user?.sub}/bookmarks/${contentId}`
    );
    subscribedPromises.current.push(cancel);
    promise
      .then((response) => {
        toggleIsFavoritted();
        removeBookmarkCallback(contentId);
      })
      .finally(() => {
        toggleIsFavoriting();
      });
  };

  const bookmarkAction = () => {
    if (isFavoritted) {
      return removeBookmark();
    }
    addBookmark();
  };

  useEffect(() => {
    if (shouldFetchBookmark) fetchBookmark();
    const cancels = subscribedPromises.current;
    return () => {
      cancels.forEach((cancel) => cancel());
    };
  }, [shouldFetchBookmark, fetchBookmark]);

  if (!show) {
    return null;
  }

  if (isFetching || isFavoriting) {
    return (
      <Spinner
        as="span"
        animation="border"
        size="sm"
        className={`favorite-icon ${classes}`}
      />
    );
  }

  if (isFavoritted) {
    return (
      <HeartIcon
        className={`favorite-icon ${classes}`}
        onClick={bookmarkAction}
      />
    );
  }
  return (
    <HeartOutlineIcon
      className={`favorite-icon ${classes}`}
      onClick={bookmarkAction}
    />
  );
};

export default FavoriteIcon;
