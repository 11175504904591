import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Tab, Nav } from "react-bootstrap";
import Recommended from "./Recommended/Recommended";
import { Onboarding } from "./Onboarding/Onboarding";
import useToggle from "hooks/useToggle";
import { get } from "utils/BeeApi";
import "./Home.scss";

import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import CheckIn from "../CheckIn/CheckIn";

const Home = ({ user }) => {
  const [showOnboarding, toggleShowOnboarding] = useToggle(false);
  const [error, setError] = useState("");
  const subscribedPromises = useRef([]);

  const fetchUserCategories = (id) => {
    const createPromise = get(`/user/${id}`, {
      params: {
        includes: ["groups"],
      },
    });
    createPromise.promise
      .then((response) => {
        const { categories } = response.data;
        if (categories.length === 0) toggleShowOnboarding();
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      });

    subscribedPromises.current.push(createPromise);
  };

  useEffect(() => {
    fetchUserCategories(user?.sub);
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => promise.cancel());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.sub]);

  return (
    <>
      <Row className="mt-2 home-tabs">
        <Tab.Container
          className="home-section-tabs"
          id="home-section-tabs"
          defaultActiveKey="check_in"
          mountOnEnter
          unmountOnExit
        >
          <Col sm={12}>
            <Nav variant="pills" className="home-section flex-row" fill>
              <Nav.Item>
                <Nav.Link className="check-in" eventKey="check_in">
                  Check-In
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="recommended">Recommended</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={12}>
            <Tab.Content>
              <Tab.Pane eventKey="check_in">
                <CheckIn user={user} />
              </Tab.Pane>
              <Tab.Pane eventKey="recommended" className="mt-4">
                <Recommended user={user} />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Tab.Container>
      </Row>
      <Onboarding isVisible={showOnboarding} onClose={toggleShowOnboarding} />
      {error && <ErrorHandler error={error} />}
    </>
  );
};

export default Home;
