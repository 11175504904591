import React from "react";
import "./AccountHeader.scss";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { Helmet } from "react-helmet";
import SearchIcon from "mdi-react/SearchIcon";
import Feedback from "../Feedback/Feedback";
import FavIcon from "../../../assets/favicon.png";

const AccountHeader = ({ user, title }) => {
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title || ""}</title>
      </Helmet>
      <Navbar
        bg="light"
        variant="light"
        expand="lg"
        className="shadow-sm MainHeader"
      >
        <Container>
          <Navbar.Brand className="d-none d-lg-block" as={Link} to="/">
            <span>InKind</span> Space
          </Navbar.Brand>
          <Navbar.Brand
            className="d-md-block d-sm-block d-xs-block d-lg-none"
            as={Link}
            to="/"
          >
            <img src={FavIcon} width="50" height="50" alt="inkind logo" />
          </Navbar.Brand>
          <Nav className="ml-auto nav-collapse-icons">
            <Feedback key={1} user={user} />
            <Nav.Link className="mr-3" as={Link} to="/search">
              <SearchIcon />
            </Nav.Link>
          </Nav>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Feedback key={2} user={user} />
              <Nav.Link
                className="mr-2 nav-desktop-icons"
                as={Link}
                to="/search"
              >
                <SearchIcon />
              </Nav.Link>
              <Nav.Item className="mr-3 mt-3 nav-collapse-icons text-muted">
                {user?.email}
              </Nav.Item>
              <Nav.Link
                className="mr-3 mt-2 nav-collapse-icons"
                as={Link}
                to="/account"
              >
                Profile
              </Nav.Link>
              <Nav.Link
                className="mr-3 nav-collapse-icons"
                as={Link}
                to="/favorites"
              >
                My Favorites
              </Nav.Link>
              <Nav.Link
                className="mr-3 nav-collapse-icons"
                as={Link}
                to="/logout"
              >
                Logout
              </Nav.Link>

              <NavDropdown
                title={<span>{user?.firstName}</span>}
                id="collasible-nav-dropdown"
                className="nav-desktop-icons"
              >
                <NavDropdown.Item disabled>{user?.email}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/account">
                  Profile
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/favorites">
                  My Favorites
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to="/logout">
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </React.Fragment>
  );
};

AccountHeader.propTypes = {
  user: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

export default AccountHeader;
