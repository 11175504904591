import React, { Component } from "react";
import { Alert } from "react-bootstrap";

import "./ErrorHandler.css";

class ErrorHandler extends Component {
  render() {
    const error = this.props.error;
    let status = "";
    let statusText = "Oh snap! You got an error!";
    let message = "Something unexpected happened. Please try again.";
    if (error) {
      status = error.status || status;
      statusText = error.statusText || statusText;
      if (error.data && error.data.error) {
        if ("message" in error.data.error) {
          message = error.data.error.message;
        } else {
          message =
            error.data.error[Object.keys(error.data.error)[0]] ?? message;
        }
      }
    }
    return (
      <Alert variant="warning" className="mt-md mb-md">
        <Alert.Heading>{statusText}</Alert.Heading>
        <p>{this.props.message || message}</p>
        <small>{status}</small>
      </Alert>
    );
  }
}

export default ErrorHandler;
