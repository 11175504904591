import React, { useEffect, useRef, useState } from "react";
import { Modal, Button, Alert, NavItem, Form, Spinner } from "react-bootstrap";
import { post } from "utils/BeeApi";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import FeedbackIcon from "mdi-react/FeedbackIcon";
import "./Feedback.scss";
import useToggle from "hooks/useToggle";

const Feedback = ({ user }) => {
  const [showModal, toggleShowModal] = useToggle(false);
  const [body, setBody] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, toggleIsLoading] = useToggle(false);
  const [actionSuccess, setActionSuccess] = useState(false);

  const subscribedPromises = useRef([]);

  const resetState = () => {
    setError(null);
    setBody("");
    setActionSuccess(false);
    toggleShowModal();
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setError(null);
    toggleIsLoading();

    let message = `${body} \r\n \r\n Page URL: ${window.location.href}`;
    const { promise, cancel } = post("/forms", {
      name: user && user.firstName,
      email: user && user.email,
      subject: `Feedback - ${
        window.location.pathname.split("/")[1] || "dashboard"
      }`,
      body: message,
    });
    subscribedPromises.current.push(cancel);

    promise
      .then((data) => {
        setActionSuccess(true);
        setBody("");
      })
      .catch((error) => {
        setError(error);
      })
      .finally(toggleIsLoading);
  };

  useEffect(() => {
    const cancels = subscribedPromises.current;
    return () => {
      cancels.forEach((cancel) => cancel());
    };
  }, []);
  return (
    <>
      <NavItem
        className={`Feedback mr-2 nav-link btn btn-sm d-flex`}
        onClick={resetState}
      >
        <FeedbackIcon />
        {<span className="d-none d-lg-block">Feedback</span>}
      </NavItem>
      <Modal
        show={showModal}
        onHide={resetState}
        backdrop="static"
        centered={true}
        size="lg"
      >
        <form
          className="FeedbackForm forms"
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <Modal.Header closeButton>
            <Modal.Title>Send us your feedback!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="font-weight-bold">
              Help us build this community by submitting your tips, questions,
              and suggestions.
            </p>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label></Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                placeholder="Tell us here..."
                name="body"
                onChange={(e) => setBody(e.target.value)}
                required
                value={body}
                autoFocus
              />
            </Form.Group>
            {error && <ErrorHandler error={error} />}
            {actionSuccess && (
              <Alert variant="success" className="mt-md mb-md">
                <h4>Thank You!</h4>
                <p>
                  We appreciate that you’ve taken the time to help us improve
                  InKind Space.
                </p>
              </Alert>
            )}
          </Modal.Body>
          <Modal.Footer>
            {actionSuccess ? (
              <Button color="primary" onClick={resetState}>
                Close
              </Button>
            ) : (
              <>
                <Button color="primary" onClick={resetState}>
                  Cancel
                </Button>
                <Button
                  color="primary mx-2"
                  type="submit"
                  onClick={handleSubmit}
                  disabled={isLoading || body === ""}
                >
                  {isLoading && (
                    <Spinner
                      className="mr-2 mb-1"
                      animation="border"
                      size="sm"
                      variant="light"
                    />
                  )}
                  Submit
                </Button>
              </>
            )}
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default Feedback;
