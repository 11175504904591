import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  Row,
  Alert,
  FormControl,
  Spinner,
  Form,
  InputGroup,
  Button,
} from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import SearchIcon from "mdi-react/SearchIcon";
import "./Search.scss";
import { slugify } from "../../../utils/StringUtils";
import moment from "moment";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import { get } from "../../../utils/BeeApi";

const limit = 10;
const groups = {
  treatmentPhaseId: "583d956e85acf",
};

const categories = {
  administrativeId: "5c34a333ba2ce",
};

const Search = ({ user }) => {
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const text = params.get("q") || "";
  const [query, setQuery] = useState(text);
  const [queryValid, setQueryValid] = useState(text.length > 2);
  const [searchList, setSearchList] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const subscribedPromises = useRef([]);
  const [expandingError, setExpandingError] = useState("");
  const [isExpanding, setIsExpanding] = useState(false);
  const [offset, setOffset] = useState(0);
  const [isLoadingTreatment, setIsLoadingTreatment] = useState(false);
  const [treatmentError, setTreatmentError] = useState("");
  const [treatmentCategories, setTreatmentCategories] = useState([]);
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const [userError, setUserError] = useState("");
  const [journeyCategories, setJourneyCategories] = useState([]);

  const handleQueryChange = (event) => {
    let value = event.target.value;
    setQuery(value);
    setQueryValid(value.length > 0);
    history.push(`/search?q=${encodeURI(value)}`, {});
  };

  useEffect(() => {
    searchContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [journeyCategories, treatmentCategories]);

  const fetchUserCategories = (id) => {
    setUserError("");
    setIsLoadingUser(true);
    const createPromise = get(`/user/${id}`, {
      params: {
        includes: ["groups"],
      },
    });
    createPromise.promise
      .then((response) => {
        const { categories } = response?.data;
        const categoryIds = categories
          .filter(
            (category) =>
              category.groups.filter(
                ({ groupId }) => groupId === groups.treatmentPhaseId
              ).length > 0
          )
          .map(({ categoryId }) => categoryId);

        setJourneyCategories(categoryIds);
        setUserError("");
        setIsLoadingUser(false);
        fetchTreatmentCategories(categoryIds);
      })
      .catch((error) => {
        !error.isCanceled && setUserError(error);
        setIsLoadingUser(false);
      });

    subscribedPromises.current.push(createPromise);
  };

  const queryBuilder = () => {
    const treatmentCategoryIds = treatmentCategories
      .filter(({ isChecked }) => isChecked)
      .map(({ categoryId }) => categoryId);

    const _query = `content.body:${query}~ OR title:${query}~  AND NOT categories.category_id:(${
      categories.administrativeId
    }) AND status:true ${
      treatmentCategoryIds.length > 0
        ? ` AND categories.category_id:(${treatmentCategoryIds.join(" OR ")})`
        : ""
    }`;

    return `${_query}`;
  };

  useEffect(() => {
    fetchUserCategories(user?.sub);
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => promise.cancel());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.sub]);

  const searchContent = (event) => {
    if (queryValid) {
      setError("");
      setIsLoading(true);

      console.log(queryBuilder());
      const createPromise = get("/search/contents", {
        params: {
          q: queryBuilder(),
          limit: limit,
          sort: "score",
          type: "bool",
          offset: offset,
        },
      });

      createPromise.promise
        .then((response) => {
          setError("");
          setSearchList(response.data || []);
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
        })
        .finally(() => {
          setIsLoading(false);
        });

      subscribedPromises.current.push(createPromise);
    }
  };

  const removeURLSnippets = (text) => {
    const regex = /\[.*\]/gi; //replace all characters between [] with and empty string
    text = text.replaceAll(regex, " ");
    text = text.split("[")[0];
    text = text.split("]")[1] ? text.split("]")[1] : text;
    return text;
  };

  const renderSearchList = () => {
    if (isLoading)
      return (
        <div className="text-center">
          <Spinner animation="border" role="loader" className="mt-4 mb-4" />
        </div>
      );

    if (error) return <ErrorHandler error={error} />;

    if (searchList.length === 0 && queryValid)
      return (
        <Alert variant="info">
          <p>There is no content associated with the above query</p>
        </Alert>
      );

    if (searchList.length === 0 && !queryValid)
      return (
        <Alert variant="info">
          <p>
            Enter your query in the above input field and you will see the
            results here.
          </p>
        </Alert>
      );

    return (
      <>
        <Row className="searchList">
          {searchList.map((item, index) => {
            return (
              <Col
                xs={12}
                md="12"
                lg="12"
                key={item.contentId}
                className="pb-3"
              >
                <h4 className="search-title mb-0 pb-0">
                  <Link
                    className="hyperlink-1"
                    to={`/contents/${item.contentId}/${slugify(item.title)}`}
                  >
                    {item.title}
                  </Link>
                  <br />
                  <small className="text-muted">
                    {moment(item.updatedAt).format("LL")}
                  </small>
                </h4>
                <p className="searchHighlight pt-1 mt-0 mb-1">
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        item.searchHits &&
                        item.searchHits.hasOwnProperty("content.body") &&
                        item.searchHits["content.body"].length > 0
                          ? ` ... ${removeURLSnippets(
                              item.searchHits["content.body"][0]
                            )} ... `
                          : "",
                    }}
                  ></span>
                </p>
                {item.categories.map((category, index) => (
                  <small key={category.categoryId} className="mr-2 text-muted">
                    {index !== 0 ? <span className="mr-2">|</span> : ""}
                    {category.categoryName}
                  </small>
                ))}
              </Col>
            );
          })}
        </Row>
        {renderBookend()}
      </>
    );
  };

  const handleSearch = (event) => {
    event.preventDefault();
    searchContent();
  };

  const expandSearchList = (offset) => {
    setExpandingError("");
    setIsExpanding(true);
    setOffset(parseInt(offset));

    const searchListPromise = get("/search/contents", {
      params: {
        q: queryBuilder(),
        limit: limit,
        sort: "score",
        type: "bool",
        offset: offset,
      },
    });
    searchListPromise.promise
      .then((response) => {
        setExpandingError("");
        setIsExpanding(false);
        setSearchList(
          searchList ? searchList.concat(...response.data) : response.data
        );
      })
      .catch((error) => {
        !error.isCanceled && setExpandingError(error);
        setIsExpanding(false);
      });

    subscribedPromises.current.push(searchListPromise);
  };

  const handleBookend = () => {
    let _offset = offset
      ? parseInt(offset) + parseInt(limit)
      : searchList.length;

    expandSearchList(_offset);
  };

  const renderBookend = () => {
    if (expandingError)
      return (
        <div className="mb-5 mt-5">
          <ErrorHandler error={expandingError} />
        </div>
      );

    if (isExpanding)
      return (
        <div className="mb-4 text-center">
          <Spinner animation="border" />
        </div>
      );

    if (searchList.length <= 0) return <div />;

    return (
      searchList.length % limit === 0 && (
        <div className="text-center mt-4">
          <Button block variant="light" onClick={handleBookend}>
            Show more
          </Button>
        </div>
      )
    );
  };

  const fetchTreatmentCategories = (categoryIds = []) => {
    setIsLoadingTreatment(true);
    setTreatmentError("");
    const createPromise = get("categories", {
      params: { group: groups.treatmentPhaseId },
    });
    createPromise.promise
      .then((response) => {
        const categories = response.data;
        setTreatmentCategories(
          categories.map((category) => {
            return {
              ...category,
              isChecked: categoryIds.includes(category.categoryId),
            };
          })
        );
        setIsLoadingTreatment(false);
        setTreatmentError("");
      })
      .catch((error) => {
        setIsLoadingTreatment(false);
        !error.isCanceled && setTreatmentError(error);
      });

    subscribedPromises.current.push(createPromise);
  };

  const renderTreatmentCategories = () => {
    if (isLoadingUser || isLoadingTreatment)
      return (
        <div className="mt-3">
          <Spinner animation="border" />
        </div>
      );

    if (userError || treatmentError)
      return <ErrorHandler error={userError || treatmentError || ""} />;

    return treatmentCategories.map((category) => {
      return (
        <Form.Check
          key={category.categoryId}
          className="mt-3"
          inline
          label={category.categoryName}
          name="searchCategoryFilter"
          type="checkbox"
          id={category.categoryId}
          value={category.categoryId}
          checked={
            category.isChecked ||
            (!category.isChecked &&
              journeyCategories.includes(category.categoryId))
          }
          onChange={handleCategoryFilter}
        />
      );
    });
  };

  const handleCategoryFilter = (event) => {
    const categoryId = event.target.value;
    // If the user unselect the selected journey category then it will be removed from the array
    // earlier, user wasn't allowed to deselect the journey categories from search filters.
    setJourneyCategories(journeyCategories.filter((cat) => cat !== categoryId));
    const _treatmentCategories = treatmentCategories.map((category) => {
      if (category.categoryId === categoryId)
        return {
          ...category,
          isChecked: !category.isChecked,
        };

      return category;
    });
    setTreatmentCategories(_treatmentCategories);
  };

  return (
    <Row>
      <Col xs={12} sm={12} md={12} lg={8} className="mt-4">
        <Form onSubmit={handleSearch} className="mb-4">
          <InputGroup>
            <FormControl
              type="text"
              placeholder="Search"
              value={query}
              onChange={handleQueryChange}
            />
            <InputGroup.Append onClick={handleSearch}>
              <InputGroup.Text id="basic-addon1">
                <SearchIcon />
              </InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
          {renderTreatmentCategories()}
        </Form>
        <h2 className="mb-3">
          Search Results{" "}
          <small className="float-right profile">
            <Link to="/account">Profile</Link>
          </small>
        </h2>
        {renderSearchList()}
      </Col>
      <Col xs={12} sm={12} md={12} lg={4}></Col>
    </Row>
  );
};

export default Search;
