import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import "./Logout.css";
import { logout } from "../../../utils/BeeApi";
import { logout as logoutDE } from "../../../utils/DeApi";
import { Button } from "react-bootstrap";

/**
 * Logout button.
 * Handles logging out of the application.
 */
class Logout extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleLogout = this._handleLogout.bind(this);

    this.state = {
      logOut: false,
      logoutError: "",
    };
    this.triggerLogout();
  }

  _handleLogout(event) {
    this.triggerLogout();
  }

  triggerLogout(event) {
    logout()
      .then((data) => {
        return logoutDE();
      })
      .then((data) => {
        this.setState({
          logOut: true,
        });
      })
      .catch((error) => {
        this.setState({
          logoutError: error.status + ": " + error.statusText,
          logOut: false,
        });
      });
  }

  render() {
    if (this.state.logOut) return <Redirect to="/login" />;
    else return <Button onClick={this.handleLogout}>Logout</Button>;
  }
}

export default Logout;
