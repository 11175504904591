import React from "react";
import "./Footer.scss";
import { Row, Col, Container } from "react-bootstrap";

const Footer = () => (
  <footer className="pt-4 Footer">
    <Container>
      <Row>
        <Col xs={12} md={12}>
          <ul className="list-inline">
            <li>
              <a href="/contents/5c1ce86a7cebe/disclaimer">Disclaimer</a>
            </li>
            <li>
              <a href="/contents/5c34a3ae510bd/terms-of-service">Privacy</a>
            </li>
            <li>© InKind Space {new Date().getFullYear()}</li>
          </ul>
          <ul className="list-inline">
            {/* <li>
              <a href="http://about.bookflow.pub">About</a>
            </li>
            <li>
              <a href="/contact">Contact</a>
            </li> */}
          </ul>
        </Col>
      </Row>
    </Container>
  </footer>
);

export default Footer;
