import React, { Component, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import "./App.css";
import Login from "./Login/Login";
import { isLoggedIn } from "../../utils/BeeApi";
import { isLoggedIn as isLoggedInDE } from "../../utils/DeApi";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import ResetPassword from "./ResetPassword/ResetPassword";
import Logout from "./Logout/Logout";
import NoMatch from "./NoMatch/NoMatch";
import PrivatePages from "../PrivatePages/PrivatePages";
import Signup from "./Signup/Signup";

/**
 * Handles application level routing.
 * It sets a middleware for private and public routes.
 */
class App extends Component {
  render() {
    return (
      <div className="Main">
        <ScrollToTop />
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/logout" component={Logout} />
          <Route path="/login-help" component={ForgotPassword} />
          <Route path="/sign-up" component={Signup} />
          <Route path="/reset-password" component={ResetPassword} />
          <PrivateRoute component={PrivatePages} />
          <Route component={NoMatch} />
        </Switch>
      </div>
    );
  }
}

/**
 * Middleware for private routes.
 */
const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        let user = isLoggedIn();
        if (user && isLoggedInDE()) {
          const userType = localStorage.getItem("utype");
          if (userType) {
            window.dataLayer.push({
              event: "pageview",
              page: {
                url: window.location.href,
              },
              eventProps: {
                userType,
              },
            });
          }
          return <Component {...props} user={user} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          );
        }
      }}
    />
  );
};

// Scroll To Top on Navigation
const ScrollToTop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
};

export default App;
