import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import "./Content.css";

import { get } from "../../utils/BeeApi";
import Loader from "../Loader/Loader";

import ErrorHandler from "../ErrorHandler/ErrorHandler";
import ContentArticle from "./ContentArticle/ContentArticle";
import ContentExternal from "./ContentExternal/ContentExternal";
import ContentEvent from "./ContentEvent/ContentEvent";
class Content extends Component {
  constructor(props) {
    super(props);
    this.state = { showFavoriteIcon: true };
    this.subscribedPromises = [];
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.contentId !== prevProps.contentId) {
      this.fetchContent();
    }
  }

  componentDidMount() {
    this.fetchContent();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function (promise) {
      promise.cancel();
    });
  }

  fetchContent() {
    this.setState({
      error: "",
      isLoading: true,
    });

    const createPromise = get(`contents/${this.props.contentId}`, {});
    createPromise.promise
      .then((response) => {
        this.setState(
          {
            content: response.data,
            error: "",
            isLoading: false,
            categories: response.data.categories,
            // if the content contains administrative category then
            // don't show favroite icon
            showFavoriteIcon:
              response.data.categories.filter(
                (content) => content.categoryId === "5c34a333ba2ce" // administrative
              ).length === 0,
          },
          () => {
            document
              .querySelector(".body-section")
              .querySelectorAll("iframe")
              .forEach((node) => {
                node.removeAttribute("width");
              });

            let iframes = document.querySelectorAll("iframe");
            if (iframes.length > 0)
              iframes.forEach((iframe) => {
                iframe.removeAttribute("height");
                iframe.removeAttribute("width");
                iframe.setAttribute(
                  "class",
                  "embed-responsive-item rounded-sm"
                );
                let wrapper = document.createElement("div");
                wrapper.className = "embed-responsive embed-responsive-16by9";

                // insert wrapper before el in the DOM tree
                iframe.parentNode.insertBefore(wrapper, iframe);
                wrapper.appendChild(iframe);
              });
          }
        );
      })
      .catch((error) => {
        !error.isCanceled &&
          this.setState({
            error: error,
            isLoading: false,
          });
      });
  }

  render() {
    return (
      <>
        <Row>
          <Col xs={12} sm={12} md={12} lg={8} className="mt-4">
            {this.renderContent()}
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} className="mt-4"></Col>
        </Row>
      </>
    );
  }

  renderContent() {
    const { content, isLoading, error, showFavoriteIcon } = this.state;
    if (isLoading) return <Loader />;
    if (error) return <ErrorHandler error={error} />;

    return content ? (
      <div>
        {content.typeId === 1 && (
          <ContentArticle {...{ showFavoriteIcon, ...content }} />
        )}
        {content.typeId === 2 && (
          <ContentEvent {...{ showFavoriteIcon, ...content }} />
        )}
        {content.typeId === 4 && (
          <ContentExternal {...{ showFavoriteIcon, ...content }} />
        )}
        <p>
          {content?.categories.map((category, index) => (
            <small key={category.categoryId} className="mr-1 text-muted">
              {index !== 0 ? <span className="mr-2">|</span> : ""}
              {category.categoryName}
            </small>
          ))}
        </p>
      </div>
    ) : (
      <div />
    );
  }
}

export default Content;
